
import { defineComponent, ref, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { debounce } from 'lodash-es';
import moment from 'moment';
import { useStore } from 'vuex';
import {
    applicableTypeClassList,
    searchDealerByCodeAndName,
    getApplicableDspData,
    checkApplicableBlank,
    saveBaseInfo,
    getDealerMainData,
    getAllCitys,
    getDealerApplicableTypeClassList,
} from '@/API/localRegistration';
export default defineComponent({
    setup() {
        const store = useStore();
        const currentUserBu: string = store.state.user.bu.orgnmen;
        const router: any = useRouter();
        const dealerName = ref<string>('');
        const dealerNameOptions = ref<any[]>([]);
        const fetching = ref<boolean>(false);
        const dealerCode = ref<string>('');
        const province = ref<string>('');
        const city = ref<string>('');
        const region = ref<string>('');
        const subRegion = ref<string>('');
        const swtCode = ref<string>('');
        const dealerGroup = ref<string>('');

        const isBlankCity = ref<boolean>(false);

        const typeClassInfo = ref<any[]>([]);

        const typeClassList = ref<any[]>([]);
        const typeClass = ref<string[]>([]);
        const preTypeClass = ref<string[]>([]);

        const specialCitys = ref<any[]>([]);
        const specialCityOptions = ref<any[]>([]);

        let basicInfo = {};

        const handleGetDealerOptions = debounce((value) => {
            dealerNameOptions.value = [];
            fetching.value = true;
            const params = {
                dealerNameCn: value,
            };
            searchDealerByCodeAndName(params).then((res: any) => {
                dealerNameOptions.value = res.data.map((item: any) => {
                    return {
                        label: item.legalNameCn,
                        value: item.legalNameCn,
                        city: item.cityCn,
                        dealerCode: item.dealerCode,
                        province: item.provinceCn,
                        swtCode: item.swtCode,
                        dealerGroup: item.dealerGroup,
                        buList: item.dealerBusinessUnit.map(
                            (item: any) => item.businessUnitEn
                        ),
                    };
                });
            });
        }, 300);

        const handleDealerChange = (value: any, dealer: any) => {
            typeClass.value = [];
            dealerCode.value = dealer.dealerCode;
            province.value = dealer.province;
            city.value = dealer.city;
            region.value = dealer.region;
            swtCode.value = dealer.swtCode;
            dealerGroup.value = dealer.dealerGroup;

            getDealerApplicableTypeClassList(dealer.buList).then((res: any) => {
                typeClassList.value = res;
            });
        };

        const handleTypeClassChange = (value: string[]) => {
            let currentTypeClass: string;
            if (preTypeClass.value.length > value.length) {
                // 取消勾选
                currentTypeClass = preTypeClass.value.filter(
                    (item) => !value.includes(item)
                )[0];
                typeClassInfo.value = typeClassInfo.value.filter(
                    (item) => item.applicableTypeClass != currentTypeClass
                );
                const isBlankList = typeClassInfo.value.map(
                    (item) => item.isBlank
                );
                isBlankCity.value = isBlankList.includes(
                    (item: any) => item === true
                );
            } else {
                // 新增勾选
                currentTypeClass = value.filter(
                    (item) => !preTypeClass.value.includes(item)
                )[0];
                const params = {
                    applicableTypeClass: currentTypeClass,
                    'dealerBasicInfo.province': province.value,
                    'dealerBasicInfo.city': city.value,
                    'dealerBasicInfo.dealerCode': dealerCode.value,
                };
                checkApplicableBlank(params).then((res: any) => {
                    const isBlank = res.data.checkStatus;
                    const tempRegion = res.data.region;
                    const subRegion = res.data.subRegion;
                    region.value = res.data.region;
                    // subRegion.value = res.data.subRegion
                    const params2 = {
                        applicableTypeClass: currentTypeClass,
                        province: province.value,
                        city: city.value,
                        region: region.value,
                    };
                    getApplicableDspData(params2).then((res: any) => {
                        if (res.data) {
                            const result = res.data;
                            result.isExpand = true;
                            result.isBlank = isBlank;
                            result.region = tempRegion;
                            result.subRegion = subRegion;
                            result.effectiveTime = null;
                            result.applicableTypeClass = currentTypeClass;
                            typeClassInfo.value.push(result);
                            const isBlankList = typeClassInfo.value.map(
                                (item) => item.isBlank
                            );
                            isBlankCity.value = isBlankList.includes(true);
                        }
                    });
                });
            }
            preTypeClass.value = value;
        };

        const handleJump = (path: string) => {
            router.push(path);
        };

        const handleSaveOrNext = () => {
            const checkList = typeClassInfo.value
                .filter((item) => item.isBlank)
                .map((item) => item.applicableTypeClass);
            const applicables = typeClassInfo.value.map((item) => {
                return {
                    id: item.id ?? '',
                    applicableTypeClass: item.applicableTypeClass,
                    region: item.region,
                    subRegion: item.subRegion,
                    status: '0',
                    effectiveTime:
                        moment(item.effectiveTime).format('YYYY-MM-DD') ===
                        'Invalid date'
                            ? null
                            : moment(item.effectiveTime).format('YYYY-MM-DD'),
                };
            });
            const params = {
                operationType: 'edit',
                specialAreas: specialCitys.value.map((item) => {
                    return {
                        city: item,
                    };
                }),
                basicInfo: basicInfo,
                checkList,
                applicables,
            };
            saveBaseInfo(params).then((res) => {
                const checkList = typeClassInfo.value
                    .filter((item) => item.isBlank)
                    .map((item) => item.applicableTypeClass);
                if (isBlankCity.value) {
                    const query = {
                        city: city.value,
                        dealerName: dealerName.value,
                        dealerCode: dealerCode.value,
                        swtCode: swtCode.value,
                        tableData: JSON.stringify(res),
                        checkList: JSON.stringify(checkList),
                    };
                    router.push({
                        path:
                            '/localRegistration/focusAreaSet/dealerAdjustment/edit2',
                        query,
                    });
                } else {
                    message.success('Save successfully');
                    router.push('/localRegistration/focusAreaList/query');
                }
            });
        };

        const init = () => {
            const route = useRoute();
            const id = route.query.id;
            const params = {
                dealerId: id,
            };
            applicableTypeClassList().then((res: any) => {
                typeClassList.value = res.map((item: string) => {
                    return {
                        value: item,
                        label: item,
                        isDisabled: false,
                    };
                });
                getDealerMainData(params).then((res: any) => {
                    res.data.applicableVOS.forEach((item: any) => {
                        item.isExpand = true;
                        item.effectiveTime = moment(item.effectiveTime);
                    });
                    basicInfo = res.data.basicInfo;
                    dealerName.value = res.data.basicInfo.dealerName;
                    dealerCode.value = res.data.basicInfo.dealerCode;
                    province.value = res.data.basicInfo.province;
                    city.value = res.data.basicInfo.city;
                    region.value = res.data.basicInfo.region;
                    swtCode.value = res.data.basicInfo.swtCode;
                    specialCitys.value = res.data.specialAreas.map(
                        (item: any) => item.city
                    );
                    dealerGroup.value = res.data.basicInfo.dealerGroup;
                    typeClassInfo.value = res.data.applicableVOS;
                    preTypeClass.value = typeClassInfo.value.map(
                        (item) => item.applicableTypeClass
                    );
                    typeClass.value = typeClassInfo.value.map(
                        (item) => item.applicableTypeClass
                    );
                    typeClassList.value.forEach((typeClassItem) => {
                        typeClass.value.forEach((l) => {
                            if (typeClassItem.value === l) {
                                typeClassItem.isDisabled = true;
                            }
                        });
                    });
                });
            });
            getAllCitys().then((res: any) => {
                specialCityOptions.value = res;
            });
        };
        init();

        return {
            currentUserBu,
            handleJump,
            handleSaveOrNext,
            isBlankCity,
            typeClassList,
            typeClass,
            typeClassInfo,
            dealerName,
            dealerCode,
            swtCode,
            province,
            city,
            dealerNameOptions,
            specialCityOptions,
            specialCitys,
            fetching,
            handleGetDealerOptions,
            router,
            handleDealerChange,
            handleTypeClassChange,
        };
    },
});
